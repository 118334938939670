import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ReturningUserContext } from './ReturningUserContext';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 16px;
  padding: 16px;
  max-width: 86vw;

  @media (max-width: 768px) {
    align-items: center;
    width: 100vw;
  }
`;

const TextField = styled.input.attrs({ required: true })`
  width: 286px;
  height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: solid 1px #959fae;
  outline: none;
  font-size: 16px;
  &::placeholder {
    color: ${({ isError }) =>
      isError ? 'red' : '#43494e'}; // Conditional color
    font-style: italic; // Italic style
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TextArea = styled.textarea.attrs({ required: true })`
  width: 1258px;
  height: 149px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: solid 1px #959fae;
  outline: none;
  font-size: 16px;
  &::placeholder {
    color: ${({ isError }) =>
      isError ? 'red' : '#43494e'}; // Conditional color
    font-style: italic; // Italic style
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LongTxtField = styled.input.attrs({ required: true })`
  width: 610px;
  height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: solid 1px #959fae;
  outline: none;
  font-size: 16px;
  &::placeholder {
    color: ${({ isError }) =>
      isError ? 'red' : '#43494e'}; // Conditional color
    font-style: italic; // Italic style
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EmailField = styled.input.attrs({
  type: 'email',
  required: true,
})`
  width: 286px;
  height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px;
  font-size: 16px;
  border: solid 1px #959fae;
  outline: none;
  &::placeholder {
    color: ${({ isError }) =>
      isError ? 'red' : '#43494e'}; // Conditional color
    font-style: italic; // Italic style
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DateField = styled.input.attrs({ required: true })`
  width: 286px;
  height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: solid 1px #959fae;
  outline: none;

  &::placeholder {
    color: ${({ isError }) =>
      isError ? 'red' : '#43494e'}; // Conditional color
    font-style: italic; // Italic style
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 91.2%;
  }
`;

const StyledSubmitRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    width: 91.2%;
  }
`;

const StyledSendRequestButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  width: 179px;
  height: 39px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  padding: 10px;
  background-color: #df0000;
`;

const StyledText = styled.p`
  color: #959fae;
`;

const Form = ({ type, returning, onSubmit }) => {
  const { isReturnUser, setReturningUser } = useContext(ReturningUserContext);
  const [dateInputType, setInputType] = useState('text');
  const [firstName, setFirstName] = useState(
    isReturnUser ? returning.FirstName : ''
  );
  const [country, setCountry] = useState(isReturnUser ? returning.Country : '');
  const [lastName, setLastName] = useState(
    isReturnUser ? returning.LastName : ''
  );
  const [phone, setPhone] = useState(isReturnUser ? returning.PhoneNumber : '');
  const [email, setEmail] = useState(isReturnUser ? returning.Email : '');
  const [company, setCompany] = useState(isReturnUser ? returning.Company : '');
  const [date, setDate] = useState();
  const [details, setDetails] = useState();

  useEffect(() => {
    setFirstName(isReturnUser ? returning.FirstName : '');
    setLastName(isReturnUser ? returning.LastName : '');
    setCountry(isReturnUser ? returning.Country : '');
    setEmail(isReturnUser ? returning.Email : '');
    setCompany(isReturnUser ? returning.Company : '');
    setPhone(isReturnUser ? returning.PhoneNumber : '');
  }, [isReturnUser]);

  function submission(e) {
    onSubmit({
      firstName,
      lastName,
      email,
      phoneNumber: phone,
      company,
      destinationCountry: country,
      requiredDeliveryDate: date,
      additionalDetails: details,
    });
    e.preventDefault();
  }

  return (
    <StyledForm onSubmit={submission}>
      <StyledRow>
        <TextField
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder={'First Name*'}
          isError={type === 'error'}
        />
        <TextField
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder={'Last Name*'}
          isError={type === 'error'}
        />
        <LongTxtField
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          placeholder={'Company*'}
          isError={type === 'error'}
        />
      </StyledRow>
      <StyledRow>
        <TextField
          placeholder={'Phone*'}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          isError={type === 'error'}
        />
        <EmailField
          placeholder={'Email*'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          isError={type === 'error'}
        />
        <TextField
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          placeholder={'Destination Country*'}
          isError={type === 'error'}
        />
        <DateField
          placeholder={'Required Delivery Date*'}
          isError={type === 'error'}
          onFocus={() => setInputType('date')}
          onBlur={() => setInputType('text')}
          type={dateInputType}
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </StyledRow>
      <StyledRow>
        <TextArea
          placeholder={'Additional Details'}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
      </StyledRow>
      <StyledSubmitRow>
        <StyledText>*Required Field</StyledText>
        <StyledSendRequestButton>Send Request</StyledSendRequestButton>
      </StyledSubmitRow>
    </StyledForm>
  );
};

export default Form;
