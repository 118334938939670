import React from 'react';
import styled from 'styled-components';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import CarouselImage1 from '../../../../media/carousel/Isolation_Barrier_Valves.jpg';
import CarouselImage2 from '../../../../media/carousel/Liner_Hangers.jpg';
import CarouselImage3 from '../../../../media/carousel/Multilateral_Intelligent_Completion.jpg';
import CarouselImage4 from '../../../../media/carousel/Production_Packers.jpg';
import CarouselImage5 from '../../../../media/carousel/Subsurface_Flow_Control.jpg';

const SplideContainer = styled(Splide)`
  margin: 20px 112px 0px 112px;
  height: 408px;

  @media (max-width: 1024px) {
    margin: 0;
  }

  @media (max-width: 704px) {
    height: 250px;
  }

  @media (max-width: 502px) {
    height: 200px;
  }

  @media (max-width: 360px) {
    height: 180px;
  }

  .splide__pagination {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }

  .splide__arrow {
    background: none;
    border: 1.5px solid white;
  }

  .splide__arrow svg {
    fill: #fff;
    height: 1em;
    width: 1em;
  }

  .splide__pagination__page {
    margin: 0 3px;
    background: white;
    border: 0.5px solid var(--color-gray3);
    height: 7px;
    width: 7px;
  }
  .splide__pagination__page.is-active {
    background: var(--color-primary);
    transform: none;
    opacity: 1;
    border: none;
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 408px;
  object-fit: cover;
  // border-radius: 12px;

  // @media (max-width: 1024px) {
  //   border-radius: 0;
  // }

  @media (max-width: 704px) {
    height: 250px;
  }
  @media (max-width: 502px) {
    height: 200px;
  }
  @media (max-width: 360px) {
    height: 180px;
  }
`;

const InfoContainer = styled.div`
  position: absolute;
  top: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Univers;
  color: white;
  @media (max-width: 704px) {
    top: 130px;
  }
  @media (max-width: 502px) {
    top: 100px;
  }

  @media (max-width: 360px) {
    top: 80px;
  }
`;

const CardTitle = styled.p`
  font-size: 48px;
  font-weight: 500;
  margin: 0;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.8);

  @media (max-width: 704px) {
    font-size: 32px;
  }

  @media (max-width: 502px) {
    font-size: 28px;
  }

  @media (max-width: 390px) {
    font-size: 26px;
  }
  @media (max-width: 360px) {
    font-size: 24px;
  }
`;

const CardSubtitle = styled.p`
  font-size: 24px;
  margin: 0;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.8);

  @media (max-width: 704px) {
    font-size: 12px;
  }
`;

const CardLink = styled.a`
  text-decoration: none;
  color: #fff;
  padding: 10px 20px;
  background-color: var(--color-primary);
  // border-radius: 10px;
  margin-top: 10px;

  @media (max-width: 502px) {
    padding: 6px 16px;
    font-size: 12px;
    // border-radius: 6px;
  }
`;

const ArticlesCarousel = () => {
  const splideOptions = {
    type: 'loop',
    heightRatio: 0.5,
    perPage: 1,
  };

  return (
    <SplideContainer options={splideOptions}>
      <SplideSlide>
        <CarouselImage src={CarouselImage1} alt="Slide 1" />
        <InfoContainer>
          <CardTitle>Isolation Barrier Valves</CardTitle>
          <CardSubtitle>
            Complete formation isolation and fluid-loss control
          </CardSubtitle>
          <CardLink
            href="https://www.halliburton.com/en/completions/well-completions/isolation-barrier-valves"
            target="_blank"
          >
            Learn More
          </CardLink>
        </InfoContainer>
      </SplideSlide>
      <SplideSlide>
        <CarouselImage src={CarouselImage2} alt="Slide 1" />
        <InfoContainer>
          <CardTitle>Liner Hangers</CardTitle>
          <CardSubtitle>Solutions for any well environment</CardSubtitle>
          <CardLink
            href="https://www.halliburton.com/en/completions/well-completions/liner-hangers"
            target="_blank"
          >
            Learn More
          </CardLink>
        </InfoContainer>
      </SplideSlide>
      <SplideSlide>
        <CarouselImage src={CarouselImage3} alt="Slide 1" />
        <InfoContainer>
          <CardTitle>Multilateral Systems</CardTitle>
          <CardSubtitle>Increase production, extend field life</CardSubtitle>
          <CardLink
            href="https://www.halliburton.com/en/completions/well-completions/multilateral-systems"
            target="_blank"
          >
            Learn More
          </CardLink>
        </InfoContainer>
      </SplideSlide>
      <SplideSlide>
        <CarouselImage src={CarouselImage4} alt="Slide 1" />
        <InfoContainer>
          <CardTitle>Production Packers</CardTitle>
          <CardSubtitle>Customized designs, reliable performance</CardSubtitle>
          <CardLink
            href="https://www.halliburton.com/en/completions/well-completions/production-packers"
            target="_blank"
          >
            Learn More
          </CardLink>
        </InfoContainer>
      </SplideSlide>
      <SplideSlide>
        <CarouselImage src={CarouselImage5} alt="Slide 1" />
        <InfoContainer>
          <CardTitle>Subsurface Flow Controls</CardTitle>
          <CardSubtitle>
            Delivering reliable equipment for over 70 years
          </CardSubtitle>
          <CardLink
            href="https://www.halliburton.com/en/completions/well-completions/subsurface-flow-controls"
            target="_blank"
          >
            Learn More
          </CardLink>
        </InfoContainer>
      </SplideSlide>
    </SplideContainer>
  );
};

export default ArticlesCarousel;
