import * as siteConfig from '../siteconfig';
import { oktaAuth } from '../services/okta-service';
import { appInsights } from '../appInsights.js';

// Filter Pane calls

export const filterTools = async (
  classId,
  dynamicFilterCategories,
  sizeMinIdValue,
  sizeMaxIdValue,
  sizeMinOdValue,
  sizeMaxOdValue,
  sizeMinLengthValue,
  sizeMaxLengthValue,
  keyword,
  resultsPerPage,
  calculatedSkip,
  classPath
) => {
  const massageRangePropertyFiltersData = (
    sizeMinIdValue,
    sizeMaxIdValue,
    sizeMinOdValue,
    sizeMaxOdValue,
    sizeMinLengthValue,
    sizeMaxLengthValue
  ) => {
    const result = {};

    const addToResult = (category, min, max) => {
      if (min || max) {
        result[category] = {};
        if (min) result[category]['min'] = parseInt(min);
        if (max) result[category]['max'] = parseInt(max);
      }
    };

    addToResult('MIN ID', sizeMinIdValue, sizeMaxIdValue);
    addToResult('MAX OD', sizeMinOdValue, sizeMaxOdValue);
    addToResult('LENGTH', sizeMinLengthValue, sizeMaxLengthValue);

    return result;
  };

  const massagePropertyFiltersData = () => {
    return dynamicFilterCategories.reduce((acc, filter) => {
      const { name, filterValues } = filter;

      if (filterValues.length > 0) {
        acc[name] = filterValues;
      }

      return acc;
    }, {});
  };

  const postData = {
    classPath: classPath
      ? `Materials Library|Drilling_Exploration And Production|${classPath}`
      : null,
    skip: calculatedSkip, //pagination
    take: resultsPerPage, // results per page
    keyword: keyword,
    propertyFilters: massagePropertyFiltersData(),
    rangePropertyFilters: massageRangePropertyFiltersData(
      sizeMinIdValue,
      sizeMaxIdValue,
      sizeMinOdValue,
      sizeMaxOdValue,
      sizeMinLengthValue,
      sizeMaxLengthValue
    ),
    responseFields: [
      'SapNumber', // Material Number
      'ProductDescription', // Name of the tool
      "Properties['Description']",
      'DescriptionLite[0]',
      'DescriptionLite[1]',
      'DescriptionLite[2]',
      'DescriptionLite[3]',
      'DescriptionLite[4]',
      'DescriptionLite[5]',
      'DescriptionLite[6]',
      'DescriptionLite[7]',
      'DescriptionLite[8]',
      'DescriptionLite[9]',
      'DescriptionLite[10]',
    ],
  };

  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/tools/filteredsearch`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',

          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(postData),
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to filter tools /${response.status}/`);
    }

    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

// Filter Pane Filter Category Names

export const retrieveDynamicFilterCategories = async (classPath, keyword) => {
  const postData = {
    classPath: classPath
      ? `Materials Library|Drilling_Exploration And Production|${classPath}`
      : null,
    keyword: keyword,
    propertyFilters: {},
    rangePropertyFilters: {},
    limit: 15,
    onlyDescriptionLiteProperties: true,
  };
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/tools/filteredsearch/properties`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(postData),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to find dynamic filter categories /${response.status}/`
      );
    }

    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};

// Filter Pane Filter Values

export const retrieveFilterCategoryValues = async (
  propertyName,
  classPath,
  keyword
) => {
  const postData = {
    classPath: classPath
      ? `Materials Library|Drilling_Exploration And Production|${classPath}`
      : null,
    keyword: keyword,
    rangePropertyFilters: {},
    propertyFilters: {},
    limit: 15,
    onlyDescriptionLiteProperties: true,
  };
  const accessToken = (await oktaAuth.tokenManager.get('accessToken'))
    ?.accessToken;
  try {
    const response = await fetch(
      `${siteConfig.searchApiUrl}/tools/filteredsearch/propertyvalues/${propertyName}`,
      {
        method: 'POST',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(postData),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to find dynamic filter category values /${response.status}/`
      );
    }

    return response.json();
  } catch (error) {
    appInsights.trackException({ error });
    throw error;
  }
};
