import React from 'react';
import styled from 'styled-components';

import { ChevronRight } from 'react-feather';

const InformationBarContainer = styled.button`
  width: 97%;
  border: none;
  background: none;
  background-color: var(--color-primary);
  padding: 16px;
  // border-radius: 6px;
  color: #fff;
  font-family: Univers;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-sizing: content-box;

  @media (max-width: 1024px) {
    width: 96%;
  }
  @media (max-width: 660px) {
    width: 92%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: baseline;
  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const Title = styled.a`
  text-decoration-color: white;
  color: #fff;
  font-family: Univers;
  font-size: 30px;
  margin: 0px;
  padding-right: 14px;
  padding-left: 24px;

  @media (max-width: 660px) {
    font-size: 24px;
  }
`;

const InformationText = styled.p`
  margin: 0px;
  font-size: 16px;
  text-align: left;
`;

const InformationBar = () => {
  return (
    <InformationBarContainer>
      <InfoContainer>
        <Title
          href="https://www.halliburton.com/en/contact-us"
          target="_blank"
        >
          {' '}
          Well Completions
        </Title>
        <InformationText>
          Engineered solutions delivered with distinctive service
        </InformationText>
      </InfoContainer>

      <ChevronRight size={32} />
    </InformationBarContainer>
  );
};

export default InformationBar;
