import React, { useState, useEffect, useContext } from 'react';
import Form from './ClientForm.jsx';
import styled from 'styled-components';
import { oktaAuth } from '../../services/okta-service.js';
import ShoppingCartViewContext from './ShoppingCartViewContext.js';
import { recentOrder } from '../../backend/shoppingcart.js';
import { useMutation } from 'react-query';
import {
  ReturningUserContextProvider,
  ReturningUserContext,
} from './ReturningUserContext.js';

const Heading = styled.h1`
  font-size: 28px;
  color: #2d3a4d;
  width: auto;
`;

const Note = styled.span`
  color: #df0000;
  font-size: 16px;
  width: auto;
  font-weight: 700;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Head = styled.div`
  display: flex;
  width: 68vw;
  height: auto;
  gap: 6px;
  align-items: start;
  padding-left: 16px;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100vw;
    padding-left: 55px;
  }
`;

const index = () => {
  const { requestQuote } = useContext(ShoppingCartViewContext);
  const { isReturnUser, setReturningUser } = useContext(ReturningUserContext);
  const [user, setUser] = useState(null);

  const { mutate: mutateRecentOrder } = useMutation(() => recentOrder(), {
    onError: (error) => {
      const errorCode = error.message.split('/');
      if (error.constructor.name === 'TypeError') {
        setError({
          title: 'Looks like something went wrong',
          message:
            'Network connection lost. Please check your network connection and try again.',
        });
      } else {
        setError({
          title: 'Looks like something went wrong',
          message:
            'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
          errorCode: `#SCPO-2-${errorCode[1]}`,
        });
      }
    },
    onSuccess: (response) => {
      const userDataIndex = 1;
      if (response[userDataIndex].Email !== '') {
        setReturningUser(true);
      }
      setUser(response[userDataIndex]);
    },
  });

  useEffect(() => {
    mutateRecentOrder();
  }, []);

  const formSubmission = async (data) => {
    requestQuote(data);
  };

  return (
    <Container>
      <Head>
        <Heading>Customer Details</Heading>
        <Note>Note: This information must be provided for every request.</Note>
      </Head>
      <Form
        type={isReturnUser ? 'returning' : ''}
        onSubmit={formSubmission}
        returning={user ?? {}}
      />
    </Container>
  );
};

export default index;
