import React, { useContext, useEffect, useState } from 'react';
import AttributeCard from './AttributeCard';
import styled from 'styled-components';
import ToolCard from './ToolCard.jsx';
import { fetchFavorites } from '../../backend/favorites.js';
import { ErrorModalContext } from '../../Modals/ErrorModal.jsx';
import { useQuery } from 'react-query';
import { fetchTool } from '../../backend/tooldetails.js';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoaderContainer = styled.div`
  margin-left: 8px;
  margin-right: 12px;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

const SkeletonRow = styled(Skeleton)`
  margin-bottom: 10px;
  // border-radius: 14px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f7f9;
  width: 100vw;
  padding: 10px 0;
`;

const ToolComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 1225px;
  width: 100%;
  border-radius: 10px;
  padding-bottom: 12.2px;
`;

const ToolComparisonContainerContent = styled.div`
  display: flex;
  align-items: start;
  gap: 19.4px;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0 12.2px;

  @media (max-width: 1024px) {
    /* Tablet view */
    justify-content: center; /* Center cards */
    flex-direction: row;
    gap: 20px;
    padding: 0;
    padding-top: 15px;

    & > * {
      flex: 0 1 calc(50% - 20px); /* 2 columns */
    }
  }

  @media (max-width: 768px) {
    /* Mobile view */
    justify-content: center; /* Center cards */
    align-items: center;
    flex-direction: column; /* Stack in single column */
    gap: 20px;
    padding: 15px;

    & > * {
      flex: 1 1 100%; /* Full width for single column */
    }
  }
`;

const Title = styled.h1`
  margin: 20px 21px;
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3a4d;
`;

const index = () => {
  const [, setError] = useContext(ErrorModalContext);
  const params = new URLSearchParams(window.location.search);
  const toolsArr = JSON.parse(`[${params.get('tools')}]`);

  const { data: favoritesData, refetch } = useQuery(
    'fetchFavorites',
    fetchFavorites,
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        if (error.constructor.name === 'TypeError') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'Network connection lost. Please check your network connection and try again.',
          });
        } else if (errorCode[1] !== '401') {
          setError({
            title: 'Looks like something went wrong',
            message:
              'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
            errorCode: `#FA-1-${errorCode[1]}`,
          });
        }
      },
    }
  );

  const referenceToolId = toolsArr[0];

  const [attrArray, setAttrArray] = useState([]);

  const getAttr = async () => {
    const data = await fetchTool(referenceToolId);
    var sample = data.DescriptionLite.slice(0, 15);
    setAttrArray(sample);
    if (attrArray.length < 15) {
      //loop through the tools (not the reference tool) to backfill the attributes array to get it to 15
      for (let index = 1; index < toolsArr.length; index++) {
        const toolData = await fetchTool(toolsArr[index]);

        //Find any attributes that do not already exist in the reference tool and add to list
        toolData.DescriptionLite.map((attr) => {
          //If the attr does not exist in the array, add it.
          const matchingAttr = sample.find((a) => a.Name === attr.Name);
          if (!matchingAttr) {
            sample = [...sample, attr];
          }
        });

        //Stop after 15
        if (sample.length >= 15) {
          break;
        }
      }
      //for any reason we go over the length of 15, trim it again here
      setAttrArray(sample.slice(0, 15));
    }
  };

  const {
    data,
    isLoading,
    error,
    refetch: refetchToolsData,
  } = useQuery(`tool-${referenceToolId}`, () => fetchTool(referenceToolId), {
    cacheTime: 0,

    onSuccess: () => {
      getAttr();
    },
    onError: (error) => {
      const errorCode = error.message.split('/');
      setError(
        error.constructor.name === 'TypeError'
          ? {
              title: 'Looks like something went wrong',
              message:
                'Network connection lost. Please check your network connection and try again.',
            }
          : {
              title: 'Looks like something went wrong',
              message:
                'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
              errorCode: `#TO-1-${errorCode[1]}`,
            }
      );
    },
  });

  useEffect(() => {
    refetchToolsData();
  }, []);

  return (
    <Container>
      {error && <h1>Tool Not Found</h1>}
      <ToolComparisonContainer>
        <Title>Tool Comparison</Title>
        <ToolComparisonContainerContent>
          {isLoading ? (
            <SkeletonLoaderContainer>
              <SkeletonRow height={550} width={200} count={1} />
            </SkeletonLoaderContainer>
          ) : (
            <AttributeCard attrArray={attrArray} />
          )}

          {toolsArr.map((toolId) =>
            isLoading ? (
              <SkeletonLoaderContainer key={toolId}>
                <SkeletonRow height={550} width={200} count={1} />
              </SkeletonLoaderContainer>
            ) : (
              <ToolCard
                key={toolId}
                firstTool={data}
                toolId={toolId}
                attrArray={attrArray}
                isFavorited={favoritesData?.some(
                  (favoritedItem) => favoritedItem.SapNumber == toolId
                )}
                refetchFavoritesData={refetch}
              />
            )
          )}
        </ToolComparisonContainerContent>
      </ToolComparisonContainer>
    </Container>
  );
};

export default index;
