import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { oktaAuth } from '../../services/okta-service.js';
import styled from 'styled-components';

import HeaderImage from '../../../media/halliburton-logo2.svg';
import { useOktaAuthContext } from '../../OktaAuthContext.js';

const HeaderContainer = styled.div`
  font-family: Univers;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;
const StyledHalLogoButton = styled.button`
  padding-left: 112px;
  height: 62px;
  background: none;
  border: none;

  cursor: pointer;
  @media (max-width: 1024px) {
    height: 35px;
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 0;
  }
`;

const StyledHalLogoImage = styled.img`
  width: 244px;
  height: 17px;
  padding-top: 15px;
  @media (max-width: 1024px) {
    height: 25px;
    padding: 0;
  }
`;

const StyledTitle = styled.div`
  color: var(--color-primary);
  font-size: 20px;
  font-weight: 500;
  flex: 1;
  text-align: center;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const AuthButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 112px;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const AuthButton = styled.button`
  font-family: Univers;

  width: 142px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  align-self: flex-end;
  margin-left: ${(props) => (props.$isSignIn ? '142px' : '')};
  background-color: var(--color-primary);
  color: white;
  border: unset;
  border-bottom: 1px solid var(--color-gray1);
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-bottom-color 0.2s ease-out;

  cursor: pointer;
  &:hover {
    background-color: var(--color-dark-red);
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const PageHeader = () => {
  const navigate = useNavigate();
  const { authState } = useOktaAuth();

  const { openModal } = useOktaAuthContext();

  const handleBackToLanding = () => {
    navigate('/');
  };

  const signOut = async (state) => {
    localStorage.setItem('signingOut', 'true');
    try {
      await oktaAuth.signOut({
        revokeAccessToken: true,
        revokeRefreshToken: true,
        clearTokensBeforeRedirect: true,
        postLogoutRedirectUri: window.location.origin + '/logout',
        state: state,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserLoginOrLogout = async () => {
    authState?.accessToken?.accessToken !== undefined
      ? await signOut(`/landing-page`)
      : openModal();
  };

  return (
    <>
      <HeaderContainer>
        <StyledHalLogoButton alt="" onClick={handleBackToLanding}>
          <StyledHalLogoImage src={HeaderImage} alt="Halliburton logo" />{' '}
        </StyledHalLogoButton>
        <StyledTitle>Product and service catalog</StyledTitle>
        <AuthButtonContainer>
          <AuthButton
            $isSignIn={authState?.accessToken?.accessToken}
            onClick={handleUserLoginOrLogout}
            alt={
              authState?.accessToken?.accessToken !== undefined
                ? 'sign out'
                : 'sign in'
            }
          >
            {authState?.accessToken?.accessToken ? 'SIGN OUT' : 'SIGN IN'}
          </AuthButton>
        </AuthButtonContainer>
      </HeaderContainer>
    </>
  );
};

export default PageHeader;
