import React, { useContext } from 'react';
import styled from 'styled-components';
import LineItem from './LineItem';

import ShoppingCartViewContext from './ShoppingCartViewContext.js';
import ApplicationContext from '../../ApplicationContext.js';

const SuggestedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  font-family: Univers;
  margin-top: 10px;
  margin-left: 112px;
  margin-right: 112px;
  background-color: rgba(217, 223, 229, 0.35);
  border: solid 1px #ccd1d9;
  // border-radius: 10px;
  padding: 10px 0 10px 0;
  pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.$isDisabled ? '0.4' : '1')};
  @media (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 70px;
    // border-radius: 0;
    padding: 0;
    background-color: #fff;
    border-left: none;
    border-right: none;
  }
`;

const GrayBar = styled.div`
  width: 100%;
  height: 33px;
  background-color: var(--color-gray4);
`;

const SuggestedItemsTitle = styled.div`
  color: var(--color-gray1);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 4px;
  text-align: center;
  @media (max-width: 1024px) {
    padding-top: 14px;
    font-size: 22px;
    text-align: left;
    margin-left: 12px;
    margin-bottom: 14px;
    padding-left: 5%;
  }
  @media (max-width: 500px) {
    padding-left: 0;
  }
`;

const SuggestedItems = () => {
  const { isDisabled, isShoppingCartLoading } = useContext(
    ShoppingCartViewContext
  );
  const { classType } = useContext(ApplicationContext);

  const suggestedItemsHash = localStorage.getItem('currentHash');
  let suggestedItems = [];
  if (suggestedItemsHash === COMMITHASH) {
    suggestedItems = JSON.parse(localStorage.getItem(`suggestedItems`)) || [];
  } else {
    localStorage.setItem('currentHash', COMMITHASH);
    localStorage.setItem('suggestedItems', JSON.stringify([]));
  }

  return (
    <SuggestedItemsContainer $isDisabled={isDisabled || isShoppingCartLoading}>
      {classType === 'compact' && <GrayBar />}
      <SuggestedItemsTitle>Recently Viewed</SuggestedItemsTitle>
      {suggestedItems.map((product) => (
        <LineItem
          key={product.materialNumber}
          productName={product.name}
          sapNumber={product.materialNumber}
          productPropertyData={product.productPropertyData}
          isSuggestedItems
        />
      ))}
    </SuggestedItemsContainer>
  );
};

export default SuggestedItems;
