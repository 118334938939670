import React, { useContext } from 'react';
import styled from 'styled-components';
import FavoritesToggleButtonComparison from '../ToolComparisonPage/FavoritesToggleButtonComparison';
import { useLocation } from 'react-router';
import { fetchTool } from '../../backend/tooldetails';
import { useQuery } from 'react-query';
import { ErrorModalContext } from '../../Modals/ErrorModal';
import UpdateItemInCartComparison from './UpdateItemInCartComparison';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoaderContainer = styled.div`
  margin-left: 8px;
  margin-right: 12px;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

const SkeletonRow = styled(Skeleton)`
  margin-bottom: 10px;
  // border-radius: 14px;
`;

const AttributesList = styled.ul`
  font-family: Univers;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  list-style: none;
  width: 200px;
  min-height: 370px;
  font-size: 18px;
  border: 1px solid #000000;
  padding-inline-start: 0;
  padding-left: 0;
  margin: 0;
  overflow: hidden;
`;

const OddListItem = styled.li`
  position: relative;
  display: flex;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: left;
  width: 90%;
  height: 27px;
  background: #ffffff;
  color: #2d3a4d;
  border-bottom: 1px solid #000000;
  font-size: 16px;
  padding: 0 5px 0 25px;
  background-color: ${(props) => (props.isDifferent ? 'yellow' : '#ffffff')};

  @media (max-width: 1024px) {
    width: 100%;
  }

  &:hover::after {
    content: '${(props) => props.tooltiptext}';
    position: absolute;
    left: 50%;
    bottom: 125%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  &::after {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
`;

const EvenListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 90%;
  height: 27px;
  background: #e4e9ed;
  color: #2d3a4d;
  border-bottom: 1px solid #000000;
  font-size: 16px;
  padding: 0 5px 0 25px;
  background-color: ${(props) => (props.isDifferent ? 'yellow' : '#e4e9ed')};

  @media (max-width: 1024px) {
    width: 100%;
  }

  &:hover::after {
    content: '${(props) => props.tooltiptext}';
    position: absolute;
    left: 50%;
    bottom: 125%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  &::after {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
`;

const Heading = styled.li`
  background: #2d394e;
  color: white;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: Univers;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
`;

const Actions = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 75px;
  width: 100%;
  gap: 8px;

  a {
    color: #00a0a5;
    text-decoration: none;
    font-size: 12px;
  }

  #add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21px;
    width: 107px;
    color: #fff;
    background-color: #df0000;
  }
`;

const EllipsisOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FavoritesToggleButtonCustom = styled(FavoritesToggleButtonComparison)`
  width: auto;
  margin: 0;
  padding: 0;
`;

const ToolCard = ({
  toolId,
  isFavorited,
  firstTool,
  refetchFavoritesData,
  attrArray,
}) => {
  const [, setError] = useContext(ErrorModalContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const fullPath = currentPath + location.search;

  const { data, isLoading, error } = useQuery(
    `tool-${toolId}`,
    () => fetchTool(toolId),
    {
      onError: (error) => {
        const errorCode = error.message.split('/');
        setError(
          error.constructor.name === 'TypeError'
            ? {
                title: 'Looks like something went wrong',
                message:
                  'Network connection lost. Please check your network connection and try again.',
              }
            : {
                title: 'Looks like something went wrong',
                message:
                  'We had trouble with your request. Please try again. If the error persists, please contact support and provide error code below',
                errorCode: `#TO-1-${errorCode[1]}`,
              }
        );
      },
    }
  );

  const currentData = data?.DescriptionLite || [];
  const firstData = firstTool.DescriptionLite || [];

  return (
    <AttributesList>
      <Heading>{data?.Name}</Heading>
      {error && <div>Tool Not Found</div>}

      {isLoading ? (
        <SkeletonLoaderContainer>
          <SkeletonRow height={370} width={200} count={1}></SkeletonRow>
        </SkeletonLoaderContainer>
      ) : (
        attrArray.map((attr, index) => {
          const sameAttrOnCurrentTool = currentData.find(
            (at) => at.Name === attr.Name
          );
          const sameAttrOnFirstTool = firstData.find(
            (at) => at.Name === attr.Name
          );

          const isDifferent =
            (sameAttrOnFirstTool === undefined &&
              sameAttrOnCurrentTool !== undefined) ||
            (sameAttrOnFirstTool !== undefined &&
              sameAttrOnCurrentTool === undefined) ||
            (sameAttrOnFirstTool !== undefined &&
              sameAttrOnCurrentTool !== undefined &&
              sameAttrOnFirstTool.Value !== sameAttrOnCurrentTool.Value);

          const ListItem = index % 2 === 0 ? OddListItem : EvenListItem;

          if (!sameAttrOnCurrentTool && sameAttrOnFirstTool) {
            return (
              <ListItem key={index} isDifferent={true} tooltiptext="N/A">
                <EllipsisOverflow>N/A</EllipsisOverflow>
              </ListItem>
            );
          }
          if (!sameAttrOnFirstTool && sameAttrOnCurrentTool) {
            return (
              <ListItem
                key={index}
                isDifferent={true}
                tooltiptext={sameAttrOnCurrentTool.Value}
              >
                <EllipsisOverflow>
                  {sameAttrOnCurrentTool.Value}
                </EllipsisOverflow>
              </ListItem>
            );
          }

          if (!sameAttrOnFirstTool && !sameAttrOnCurrentTool && attr) {
            return (
              <ListItem key={index} isDifferent={false} tooltiptext="N/A">
                <EllipsisOverflow>N/A</EllipsisOverflow>
              </ListItem>
            );
          }

          return (
            <ListItem
              key={index}
              isDifferent={isDifferent}
              tooltiptext={sameAttrOnCurrentTool.Value}
            >
              <EllipsisOverflow>{sameAttrOnCurrentTool.Value}</EllipsisOverflow>
            </ListItem>
          );
        })
      )}

      {isLoading ? (
        <SkeletonLoaderContainer>
          <SkeletonRow height={75} width={200} count={1}></SkeletonRow>
        </SkeletonLoaderContainer>
      ) : (
        <Actions>
          <FavoritesToggleButtonCustom
            pagePath={fullPath}
            sapNumber={data?.SapNumber}
            isFavorited={isFavorited}
            refetchFavoritesData={refetchFavoritesData}
          />
          <UpdateItemInCartComparison
            toolData={data}
            oktaRedirectOnLoginTo={`/tool/${data?.SapNumber}`}
          />
        </Actions>
      )}
    </AttributesList>
  );
};

export default ToolCard;
